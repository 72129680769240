var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":`#${_vm.workOrder.number} ${_vm.workOrder.classification.title}`,"sub-heading":`For ${_vm.workOrder.customer.name} at ${_vm.workOrder.location.name}`}})],1),_c('column',{staticClass:"is-narrow"},[_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                    name: 'work-order-manager',
                    params: {
                        workOrder: _vm.workOrder.uuid
                    }
                }}},[_vm._v("Summary")]),_c('download-button',{staticClass:"is-rounded",attrs:{"path":`/work-orders/${_vm.workOrder.uuid}/download-pdf`,"file-name":`${_vm.workOrder.number}-${_vm.workOrder.classification.title}.pdf`}},[_vm._v(" Download Report ")]),(_vm.workOrder.status === 'in progress' && !_vm.$root.isPassiveUser())?_c('action-button',{on:{"click":_vm.markAsComplete}},[_vm._v(" Mark as Complete ")]):_vm._e(),(_vm.workOrder.status === 'complete' && !_vm.$root.isPassiveUser())?_c('action-button',{on:{"click":_vm.reopen}},[_vm._v(" Reopen ")]):_vm._e(),(_vm.$root.can('manage-work-orders') && !_vm.$root.isPassiveUser())?_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                        name: 'edit-work-order',
                        params: {
                            workOrder: _vm.workOrder.uuid
                        }
                    }}},[_vm._v(" Edit ")]):_vm._e()],1)])],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }