<template>
<loader v-bind="{ loading }">
    <columns>
        <column>
            <page-heading
                :heading="`#${workOrder.number} ${workOrder.classification.title}`" 
                :sub-heading="`For ${workOrder.customer.name} at ${workOrder.location.name}`"
            />
        </column>
        <column class="is-narrow">
            <div class="buttons has-addons is-rounded">
                <router-link :to="{
                    name: 'work-order-manager',
                    params: {
                        workOrder: workOrder.uuid
                    }
                }" class="button is-rounded">Summary</router-link>
                <download-button
                    :path="`/work-orders/${workOrder.uuid}/download-pdf`"
                    :file-name="`${workOrder.number}-${workOrder.classification.title}.pdf`"
                    class="is-rounded">
                    Download Report
                </download-button>
                <action-button 
                    v-if="workOrder.status === 'in progress' && !$root.isPassiveUser()"
                    @click="markAsComplete">
                    Mark as Complete
                </action-button>
                <action-button 
                    v-if="workOrder.status === 'complete' && !$root.isPassiveUser()"
                    @click="reopen">
                    Reopen
                </action-button>
                <router-link 
                    v-if="$root.can('manage-work-orders') && !$root.isPassiveUser()"
                    :to="{
                        name: 'edit-work-order',
                        params: {
                            workOrder: workOrder.uuid
                        }
                    }" 
                    class="button is-rounded">
                    Edit
                </router-link>
            </div>
        </column>
    </columns>

    <router-view />
</loader>
</template>
<script>
import { mapGetters } from 'vuex'
import { workOrder as backend } from '@/api'
export default {

    data: () => ({
        loading: true
    }),

    beforeCreate() {
        this.$store.dispatch('workOrder/load', this.$route.params.workOrder)
            .finally(() => this.loading = false)
    },

    beforeDestroy() {
        this.$store.commit('workOrder/clearWorkOrder')
    },

    methods: {
        async markAsComplete() {
            if(await this.$confirm({
                title: 'Close Work Order',
                message: 'Are you sure you want to mark this work order as complete?'
            })) {
                backend.markAsComplete(this.workOrder.uuid, () => {
                    console.log('completed')
                    this.$store.commit('workOrder/updateStatus', 'complete')
                }, () => {})
            }
        },
        async reopen() {
            if(await this.$confirm({
                title: 'Reopen Work Order',
                message: 'Are you sure you want to mark this work order as in progress?'
            })) {
                backend.reopen(this.workOrder.uuid, () => {
                    console.log('in progress')
                    this.$store.commit('workOrder/updateStatus', 'in progress')
                }, () => {})
            }
        }
    },

    computed: {
        ...mapGetters('workOrder', [
            'workOrder'
        ])
    }

}
</script>